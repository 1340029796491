import { default as privacy_policyPuXsLnXorTMeta } from "/Users/eimei/Documents/projects/stack3webapp/pages/app/[appId]/[lang]/privacy_policy.vue?macro=true";
import { default as term_of_useFqcTg7sQiOMeta } from "/Users/eimei/Documents/projects/stack3webapp/pages/app/[appId]/[lang]/term_of_use.vue?macro=true";
import { default as how_to_detect_cycling05B9lL2HaIMeta } from "/Users/eimei/Documents/projects/stack3webapp/pages/app/bike/how_to_detect_cycling.vue?macro=true";
import { default as indexwJSvc3LkGuMeta } from "/Users/eimei/Documents/projects/stack3webapp/pages/app/index.vue?macro=true";
import { default as indexN4Y78THclhMeta } from "/Users/eimei/Documents/projects/stack3webapp/pages/index.vue?macro=true";
import { default as enucwLDXUantMeta } from "/Users/eimei/Documents/projects/stack3webapp/pages/int/en.vue?macro=true";
import { default as koXmSf8VJhn4Meta } from "/Users/eimei/Documents/projects/stack3webapp/pages/int/ko.vue?macro=true";
import { default as zh_hansmORzspX7w7Meta } from "/Users/eimei/Documents/projects/stack3webapp/pages/int/zh_hans.vue?macro=true";
import { default as zh_hantF072Bof5rWMeta } from "/Users/eimei/Documents/projects/stack3webapp/pages/int/zh_hant.vue?macro=true";
export default [
  {
    name: "app-appId-lang-privacy_policy",
    path: "/app/:appId()/:lang()/privacy_policy",
    meta: privacy_policyPuXsLnXorTMeta || {},
    component: () => import("/Users/eimei/Documents/projects/stack3webapp/pages/app/[appId]/[lang]/privacy_policy.vue").then(m => m.default || m)
  },
  {
    name: "app-appId-lang-term_of_use",
    path: "/app/:appId()/:lang()/term_of_use",
    meta: term_of_useFqcTg7sQiOMeta || {},
    component: () => import("/Users/eimei/Documents/projects/stack3webapp/pages/app/[appId]/[lang]/term_of_use.vue").then(m => m.default || m)
  },
  {
    name: "app-bike-how_to_detect_cycling",
    path: "/app/bike/how_to_detect_cycling",
    meta: how_to_detect_cycling05B9lL2HaIMeta || {},
    component: () => import("/Users/eimei/Documents/projects/stack3webapp/pages/app/bike/how_to_detect_cycling.vue").then(m => m.default || m)
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/Users/eimei/Documents/projects/stack3webapp/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/eimei/Documents/projects/stack3webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "int-en",
    path: "/int/en",
    component: () => import("/Users/eimei/Documents/projects/stack3webapp/pages/int/en.vue").then(m => m.default || m)
  },
  {
    name: "int-ko",
    path: "/int/ko",
    component: () => import("/Users/eimei/Documents/projects/stack3webapp/pages/int/ko.vue").then(m => m.default || m)
  },
  {
    name: "int-zh_hans",
    path: "/int/zh_hans",
    component: () => import("/Users/eimei/Documents/projects/stack3webapp/pages/int/zh_hans.vue").then(m => m.default || m)
  },
  {
    name: "int-zh_hant",
    path: "/int/zh_hant",
    component: () => import("/Users/eimei/Documents/projects/stack3webapp/pages/int/zh_hant.vue").then(m => m.default || m)
  }
]